import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CabHttpService, ContextType } from '../services/cab-http.service';
import { map } from 'rxjs';
import { NavTreeNode, NavTreeNodeStatus } from '@epsilon/pcbc-nav-tree';

@Injectable({
  providedIn: 'root'
})
export class ManageTrackedService {
  constructor(
    private http: HttpClient,
    private cabHttpService: CabHttpService
  ) {}

  getTrackedAttributes(body) {
    const url = this.cabHttpService.apiUrl(
      ContextType.CAB,
      '/cab/v1/campaign-extract/tracked-attributes/search'
    );
    return this.http.post(url, body);
  }

  metaUrlHeaders(): Record<string, any> {
    return {};
  }

  metaUrl(dataUniverseId: string): string {
    return this.cabHttpService.apiUrl(
      ContextType.CAB,
      `/cab/v1/audience-builder/${dataUniverseId}/hierarchy`
    );
  }

  createTrackedAttribute(body) {
    const url = this.cabHttpService.apiUrl(
      ContextType.CAB,
      '/cab/v1/campaign-extract/tracked-attributes'
    );
    return this.http.post(url, body);
  }

  fetchAttributes(
    contextId: string,
    dataUniverseId: string,
    dedupeId: string,
    recordLimit: number,
    path: any[],
    parentId?: string,
    offset?: number,
    searchId?: string
  ) {
    const headers = this.metaUrlHeaders();
    headers['x-cab-context'] = contextId;
    const requestBody = {
      dedupeIdentityType: dedupeId,
      limit: recordLimit,
      offset: offset ? offset : 0,
      cabId: parentId,
      path,
      searchId
    };
    return this.http
      .post(this.metaUrl(dataUniverseId), requestBody, {
        headers
      })
      .pipe(map((res) => this.parseAudienceResponse(res)));
  }

  private parseAudienceResponse(pickerData, isAttributeSearch = false) {
    const childNodes = pickerData.results.map((attribute) =>
      isAttributeSearch
        ? this.transformAttributeSearchToTreeNode(attribute)
        : this.transformToTreeNode(attribute)
    );
    pickerData.results = childNodes;
    return pickerData;
  }

  private transformAttributeSearchToTreeNode(nodeResponse: any): NavTreeNode {
    let child = [];
    if (!nodeResponse.leaf) {
      const nodeChildren = nodeResponse.children;
      child = nodeChildren.map(this.transformToTreeNode) || [];
    }
    return {
      ...nodeResponse,
      id: nodeResponse.id,
      name: nodeResponse.displayName,
      type: nodeResponse.dataType,
      children: nodeResponse.leaf ? undefined : child,
      status:
        nodeResponse.hasMore || !nodeResponse.leaf
          ? NavTreeNodeStatus.PARTIAL
          : NavTreeNodeStatus.COMPLETE,
      data: nodeResponse
    } as NavTreeNode;
  }

  private transformToTreeNode(nodeResponse: any): NavTreeNode {
    return {
      ...nodeResponse,
      id: nodeResponse.id,
      name: nodeResponse.displayName,
      type: nodeResponse.dataType,
      children: nodeResponse.leaf
        ? undefined
        : nodeResponse.results?.map(this.transformToTreeNode) || [],
      status:
        nodeResponse.hasMore || !nodeResponse.leaf
          ? NavTreeNodeStatus.PARTIAL
          : NavTreeNodeStatus.COMPLETE,
      data: nodeResponse
    } as NavTreeNode;
  }
}
