<coreui-modal modalWidth="40" #alertModal>
  <ng-template #titleTemplate>
    <div class="titleTemplate">
      <span coreUIIcon="warning" class="Modal-titleIcon"></span>{{title}}
    </div>
  </ng-template>
  <ng-template #titleCloseButtonTemplate>
    <button type="button" aria-label="Close" (click)="cancel()" coreUIButton="icon-large">
      <i coreUIIcon="close"></i>
    </button>
  </ng-template>
  <ng-template #contentTemplate>
    <span>{{ confirmationMessage }}</span>
  </ng-template>
  <ng-template #buttonsTemplate>
    <button type="button" coreUIButton="secondary" (click)="cancel()" coreUITrapFocusInitial>
      {{ cancelButtonText }}
    </button>
    <button type="button" coreUIButton="primary" (click)="confirm()">
      {{ confirmButtonText }}
    </button>
  </ng-template>
</coreui-modal>