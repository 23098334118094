import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { COREUI_DEFAULT_THEME, CoreUIModule, Theme } from '@epsilon/core-ui';

import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { runtimeChecks } from '@epsilon-cdp/cab-lib';
import { PcmCommonModule } from '@epsilon-cdp/pcm-common-lib';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ENV_CONFIG_MAP } from 'projects/cab/src/lib/environments/environment-config';
import { RequestLoadingService } from 'projects/cab/src/lib/services/request-loading.service';
import { environment } from '../environments/environment';
import { AppConfigService } from './app-config.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ContextsComponent } from './contexts/contexts.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { FeatureFlagService } from './feature-flag.service';
import { AuthInterceptor } from './interceptor/auth.interceptor';
import { LoginComponent } from './login/login.component';
import { NotAuthorizeComponent } from './not-authorize/not-authorize.component';
import { AuthenticationService } from './services/authentication.service';
import { OverrideConfigService } from './services/override-config.service';
import { UserPermissionsService } from './user-permissions.service';

const ENVIRONMENT = 'ENVIRONMENT';
const environmentConfigFactoryApp = (environment, appConfigService: any) => {
  const environmentConfig = ENV_CONFIG_MAP.get(environment);
  environmentConfig.productBaseUrl =
    appConfigService.getProductBaseUrlForCabLib();
  return environmentConfig;
};

@NgModule({ declarations: [
        AppComponent,
        ContextsComponent,
        LoginComponent,
        NotAuthorizeComponent,
        ErrorPageComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        CoreUIModule,
        AppRoutingModule,
        StoreModule.forRoot({}, { runtimeChecks }),
        StoreDevtoolsModule.instrument({ maxAge: 50 }), // Max age is the total number of past states the extension will store
        EffectsModule.forRoot([]),
        ReactiveFormsModule,
        PcmCommonModule], providers: [
        {
            provide: COREUI_DEFAULT_THEME,
            useValue: Theme.Light
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestLoadingService,
            multi: true
        },
        {
            provide: 'ENVIRONMENT',
            useValue: environment.domainName
        },
        {
            provide: 'APP_CONFIG',
            useExisting: AppConfigService
        },
        {
            provide: 'FEATURE_ENABLED',
            useExisting: FeatureFlagService
        },
        {
            provide: 'HTTP_CLIENT_SERVICE',
            useExisting: HttpClient
        },
        {
            provide: 'environmentConfigApp',
            useFactory: environmentConfigFactoryApp,
            deps: [ENVIRONMENT, 'APP_CONFIG']
        },
        {
            provide: 'USER_PERMISSIONS',
            useExisting: UserPermissionsService
        },
        {
            provide: 'AUTHENTICATION_SERVICE',
            useExisting: AuthenticationService
        },
        {
            provide: 'OVERRIDE_CONFIG',
            useClass: OverrideConfigService
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
