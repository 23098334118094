import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploadOptions, FileUploadService, NotificationComponent } from '@epsilon/core-ui';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { EnumSetValues } from '../admin.models';
import { AdminService } from '../admin.service';
import { UtilsService } from '../../utils/utilservice';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MyFileUploadOptions: FileUploadOptions = {
  url: 'http://localhost:8000/upload',
  formFieldName: 'file',
  options: {
    params: new HttpParams().set('Authorization', '123-XYZ'),
    responseType: 'arraybuffer'
  }
};
@UntilDestroy()
@Component({
  selector: 'lib-add-enumeration-modal',
  templateUrl: './enumeration.component.html',
  styleUrls: ['./enumeration.component.sass'],
  providers: [
    FileUploadService,
    {
      provide: FileUploadOptions,
      useValue: MyFileUploadOptions
    }
  ]
})
export class EnumerationComponent implements OnInit{
  @ViewChild('enumCreationSuccess', { static: true }) public enumCreationSuccess;
  @ViewChild('enumCreationFromFileError', { static: true }) public enumCreationFromFileError;
  @ViewChild('fileUploadError', { static: true }) public fileUploadError: NotificationComponent;
  creationResultMessage: string;
  notificationStyle: string;
  enumerationForm: UntypedFormGroup = this.formBuilder.group({
    enumerationName: new UntypedFormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    description: new UntypedFormControl(),
    enumeratedValueInput: new UntypedFormControl(),
    enumeratedKeyValue: new UntypedFormControl(),
    enumerationDataType: new UntypedFormControl(),
    enumValuesFromFileAppendOrReplace: new UntypedFormControl('replaceValue'),
    uploadEnumValues: []
  });
  contextId: string;
  parentRouter: string;
  enumeratedValues: Array<EnumSetValues> = new Array<EnumSetValues>();
  displayableItems: BehaviorSubject<Array<EnumSetValues>>;
  enumValuesFile: File;
  enumValuesFileName = '';
  enumValuesFileSize = '';

  showHide = true;
  enumeratedValueExisted: boolean;
  enumId: string;
  isEditEnum: boolean;
  enumerationBreadCrumbName = 'New Enumeration';
  enumerationDataTypes: string[] = ['Boolean', 'Date', 'Number',   'String'];
  defaultDataTye = 'BOOLEAN';
  enumVersion = 0;

  public get showHideText(): string {
    // i18n
    return this.showHide ? 'Show more...' : 'Show less...';
  }

  constructor( public formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private adminService: AdminService,private utilsService: UtilsService) {}

  ngOnInit() {
    this.contextId = this.route.snapshot.paramMap.get('contextId');
    this.enumId = this.route.snapshot.paramMap.get('enumId');
    this.enumeratedValues = [];
    this.displayableItems = new BehaviorSubject([]);
    if(this.enumId) {
      this.isEditEnum = true;
      this.adminService.getEnumData(this.contextId,this.enumId).subscribe(res => {
        this.enumerationForm.get('description').setValue(res.description);
        this.enumerationForm.get('enumerationName').setValue(res.name);
        this.enumerationBreadCrumbName = res.name;
          this.enumeratedValues = res.values;
        const items = this.showHide ? this.enumeratedValues?.slice(0, 3) : this.enumeratedValues;
        this.displayableItems = new BehaviorSubject(items);
        this.enumerationForm.get('enumerationDataType').setValue(res.dataType);
        this.enumVersion = res.version;
      });
    }
  }

  saveEnumeration() {
    if(!this.enumerationForm.valid) {
      return;
    }
    //ToDo Need to add values NOSONAR
   const enumPayload: any = {
     cabContextId: this.contextId,
     dataType: this.enumerationForm.get('enumerationDataType').value,
     id: this.enumId,
     description: this.enumerationForm.get('description').value,
     displayName: this.enumerationForm.get('enumerationName').value,
     name: this.enumerationForm.get('enumerationName').value,
     values: this.enumeratedValues,
     version: this.enumVersion
   };
   this.adminService.createEnum(this.contextId, enumPayload).subscribe({
    next: () => {
      this.notificationStyle = 'success';
      this.creationResultMessage = 'created successfully';
      this.enumCreationSuccess.show();
      this.routeToEnumerations();
    },
    error: (errorResponse) => {
      if (errorResponse.error) {
        this.notificationStyle = 'error';
        this.creationResultMessage = 'creation failed';
        this.enumCreationSuccess.show();
      }
    }
  });
  }

  saveEnumerationFromFile() {
    if(!this.enumerationForm.valid) {
      return;
    }
    const enumPayload: any = {
      cabContextId: this.contextId,
      //ToDo- Need to get value from datatype dropdown NOSONAR
      dataType: 'BOOLEAN',
      id: this.enumId,
      description: this.enumerationForm.get('description').value,
      displayName: this.enumerationForm.get('enumerationName').value,
      name: this.enumerationForm.get('enumerationName').value,
      keepExisting: this.enumerationForm.get('enumValuesFromFileAppendOrReplace').value === 'replaceValue' ? false : true,
      version: 0
    };
    this.adminService.createEnumFromFile(this.contextId, this.enumValuesFile, enumPayload).subscribe({
      next: () => {
        this.notificationStyle = 'success';
        this.creationResultMessage = 'created successfully';
        this.enumCreationSuccess.show();
        this.routeToEnumerations();
      },
      error: (errorResponse) => {
        if (errorResponse.error?.errorDetails) {
          this.notificationStyle = 'error';
          this.creationResultMessage = errorResponse.error.errorDetails[0].errorMessage;
          this.enumCreationFromFileError.show();
        }
      }
    });
  }

  public onUploadFileStateChanges(event) {
    if (event.target.files.length > 0) {
      this.enumValuesFile = event.target.files[0];
      this.enumValuesFileName = event.target.files[0].name;
      this.enumValuesFileSize = (event.target.files[0].size)/1000 + ' KB';
    }
  }

  removeEnumValueFile() {
    this.enumValuesFile = null;
    this.enumValuesFileName = '';
    this.enumValuesFileSize = '';
  }

  cancelEnumeration() {
    this.routeToEnumerations();
  }

  cancelEnumerationFromFile() {
    this.routeToEnumerations();
  }

  routeToEnumerations() {
    this.router.navigate([this.utilsService.getProductBaseUrl(this.router, this.route), this.contextId, 'admin', 'enumerations']);
  }

  public addItem() {
    if (this.enumerationForm.get('enumeratedValueInput').value.trim() === '') {
      return;
    }
    // Restrict strings and allow only numbers for 'Number' datatype
    if (this.enumerationForm.get('enumerationDataType').value === 'Number' && !(/^\d+$/.test(this.enumerationForm.get('enumeratedValueInput').value))) {
      return;
    }
    if(this.enumeratedValues.findIndex(value => value.id.toLowerCase() === this.enumerationForm.get('enumeratedValueInput').value.toLowerCase()) !== -1) {
      this.enumeratedValueExisted = true;
      return;
    } else {
      this.enumeratedValueExisted = false;
    }
    this.enumeratedValues.unshift({displayName: this.enumerationForm.get('enumeratedValueInput').value, id:  this.enumerationForm.get('enumeratedValueInput').value});
    this.setDisplayableItems();
    this.enumerationForm.get('enumeratedValueInput').setValue('');
    this.enumerationForm.get('enumeratedKeyValue').setValue('');
  }

  public onRemoveAll() {
    this.enumeratedValues.length = 0;
    this.setDisplayableItems();
  }

  public onRemoveItem(index: number) {
    this.enumeratedValues.splice(index, 1);
    this.setDisplayableItems();
  }

  public onShowHide(): void {
    this.showHide = !this.showHide;
    this.setDisplayableItems();
  }

  private setDisplayableItems(): void {
    const items = this.showHide ? this.enumeratedValues.slice(0, 3) : this.enumeratedValues;
    this.displayableItems.next(items);
  }

  updateEnumVal($event: any, i: number) {
    this.enumeratedValues[i].displayName = $event.target.value;
  }
}
