<coreui-notification #generalErrorNotification notificationStyle="error" [isPersistent]="false">
  There was an error while attempting your action. Please try again later.
</coreui-notification>

<lib-attribute-selection [attributeOptions]="showSearchTree ? searchNodes : rootNodes"
  [showAsSearchResults]="showSearchTree" [breadcrumbs]="breadcrumbs"
  [showSearchInput]="attributeSearchEnabled && breadcrumbs.length > 1"
  (attributeOptionClicked)="fetchChildrenNew($event)" (breadcrumbClicked)="onBreadcrumbClicked($event)"
  (searchUpdated)="onSearchUpdated($event)" [loadingNodes]="loadingNodes || isLoading" [showLoadMore]="loadMore"
  (loadMoreClicked)="onLoadMore($event)" [currentActiveFilter]="selectedFilter" [isRegularSearch]="isRegularSearch"
  [showSearchLimit]="showSearchLimit"></lib-attribute-selection>