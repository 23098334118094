import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorPageHandlerService extends HttpHandler {
  constructor(private readonly next: HttpHandler, private router: Router) {
    super();
  }

  handle(httpRequest: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.next.handle(httpRequest).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            if (event.status == 404 || event.status === 401) {
              this.router.navigate([`error/${event.status}`]);
            }
          }
          return event;
        },
        error: (error) => {
          if (error.status === 401) {
            this.router.navigate([`error/${error.status}`]);
          } else if (error.status === 404) {
            if(error?.url?.includes('edit-details')) {
              this.router.navigate([`error/${error.status}`]);
            }
          } else if (error.status === 500) {
            this.router.navigate([`error/${error.status}`]);
          }
        },
      }),
      finalize(() => {
        console.log('finalize');
      })
    );
  }
}
