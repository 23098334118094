import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { compact, first, map as _map, orderBy, remove } from 'lodash-es';
import { NavTreeNode } from '@epsilon/pcbc-nav-tree';
import { PickerService } from './picker.service';
import { DetailsModalService } from '../services/details-modal.service';
import { DataSetType } from '../enums/data-types';
import { cabPathList } from '../audience/audience.utils';
import { DedupeType } from '../models/dedupe-type';
import {
  FEATURE_ATTRIBUTE_SEARCH,
  FEATURE_AUDIENCES,
} from '../utils/feature-utils';
import { AudienceBuilderService } from '../audience-builder/audience-builder.service';
import { ActivatedRoute } from '@angular/router';
import { FeatureService } from '../utils/feature-service';
import { NotificationComponent } from '@epsilon/core-ui';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { selectContext } from '../context/context.reducer';
import { cloneDeep } from 'lodash';
import { UtilsService } from '../utils/utilservice';

@UntilDestroy()
@Component({
  selector: 'lib-picker',
  templateUrl: './picker.component.html',
  styleUrls: ['./picker.component.sass'],
})
export class PickerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() contextId: string;
  @Input() dataUniverseId: string;
  @Input() recordLimit = 20;
  @Input() activeDedupeType: DedupeType;
  @Input() isProductTypeDcdp?: boolean;
  attributeSearchEnabled: boolean;
  audiencesEnabled: boolean;
  searchNodes: NavTreeNode[] = [];
  showSearchTree = false;
  searchText = '';
  showSearchLimit = false;
  loadingNodes = false;
  attributeSearchDropdownOptions = [
    { label: 'Attributes', value: 'DATA_SET_ATTRIBUTE' },
    { label: 'Definitions', value: 'AUDIENCE_DEFINITION' },
    { label: 'Lists', value: 'AUDIENCE_LIST' }
  ];
  @ViewChild('generalErrorNotification')
  generalErrorNotification: NotificationComponent;
  selectedFilter: string;
  audienceDefinitionId: string;
  breadcrumbs = [
    {
      displayName: 'All Criteria'
    }
  ];

  rootNodes = [];
  loadMore = false;
  isLoading = false;
  searchId = null;
  isRegularSearch = false;
  currentNode;
  businessUnitId: string;

  constructor(
    private pickerService: PickerService,
    public detailsModalService: DetailsModalService,
    private cd: ChangeDetectorRef,
    public audienceBuilderService: AudienceBuilderService,
    private route: ActivatedRoute,
    public featureService: FeatureService,
    public store: Store<AppState>,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.attributeSearchEnabled = this.featureService.isFeatureEnabled(
          FEATURE_ATTRIBUTE_SEARCH
        );
        this.audiencesEnabled =
          this.featureService.isFeatureEnabled(FEATURE_AUDIENCES);
      });
    if (!this.audiencesEnabled) {
      this.attributeSearchDropdownOptions =
        this.attributeSearchDropdownOptions.filter(
          (dropdown) => dropdown.value !== 'AUDIENCE_LIST'
        );
    }
    this.audienceDefinitionId =
      this.route.snapshot.paramMap.get('definitionId');
    if (this.isProductTypeDcdp) this.recordLimit = 10;
    this.businessUnitId = this.utilsService.getBusinessUnitId(this.route);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['activeDedupeType'] &&
      changes['activeDedupeType'].previousValue !==
        changes['activeDedupeType'].currentValue
    ) {
      this.activeDedupeType = changes['activeDedupeType']?.currentValue;
      this.searchText = '';
      this.showSearchLimit = false;
      this.showSearchTree = false;
      this.searchNodes = [];
      this.fetchRoot();
    }
  }

  ngAfterViewInit(): void {
    this.cd.detectChanges();
  }

  fetchRoot() {
    if (this.activeDedupeType) {
      if (this.currentNode?.cabId === 'cab:datasetcat:datasetcat') {
        this.recordLimit = 1000;
      }
      else if (
        this.currentNode?.cabId === 'cab:auddefcat:auddefcat' ||
        this.currentNode?.cabId === 'cab:audlistcat:audlistcat'
      ) {
        this.recordLimit = 20;
        if (this.isProductTypeDcdp) this.recordLimit = 10;
      }
      this.pickerService
        .fetchAttributes(
          this.contextId,
          this.dataUniverseId,
          this.activeDedupeType,
          this.recordLimit,
          null,
        )
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (response) => {
            const nodes = [];
            const rootNodes = response.results;
            this.searchId = response?.searchId ?? null;
            const fixedOptions = new Map();
            fixedOptions.set(
              'Audience Definitions',
              this.isProductTypeDcdp ? 'Definitions' : 'Audience Definitions'
            );
            fixedOptions.set(
              'Audience Lists',
              this.isProductTypeDcdp ? 'Lists' : 'Audience Lists'
            );
            fixedOptions.set(
              'Marketing Catalog',
              this.isProductTypeDcdp
                ? 'Attributes from Data Objects'
                : 'Marketing Catalog'
            );
            const fixedNodes = compact(
              _map(fixedOptions.keys(), (nodeName) =>
                first(
                  remove(
                    rootNodes,
                    (node: NavTreeNode) => node['displayName'] === nodeName
                  )
                )
              )
            );
            if (this.isProductTypeDcdp) {
              rootNodes.forEach((node) => {
                if (fixedOptions.has(node.displayName)) {
                  node.name = fixedOptions.get(node.name);
                  node.displayName = fixedOptions.get(node.displayName);
                }
              });
            }
            let orderedNodes = [
              ...fixedNodes,
              ...orderBy(rootNodes, 'displayName'),
            ];
            if (!this.audiencesEnabled) {
              orderedNodes = orderedNodes.filter(
                (node) => node.displayName !== 'Lists'
              );
            }
            orderedNodes.forEach((n) => nodes.push(n));
            this.rootNodes = [...nodes];
          },
          error: (err) => {
            this.generalErrorNotification.show();
            console.log(err);
          }
        });
    }
  }

  fetchChildrenNew(navTreeNode, breadcrumbEvent = false) {
    this.generalErrorNotification.hide();
    this.loadingNodes = true;
    this.searchNodes = [];
    this.showSearchTree = false;
    let startIndex = breadcrumbEvent ? 0 : navTreeNode?.children?.length ?? 0;
    if (startIndex > 0 && navTreeNode['dataSetType'] === DataSetType.DATA_SET) {
      startIndex = navTreeNode?.children.filter((node) => !node['leaf']).length;
    }
    this.recordLimit = 1000;
    let searchId = null;
    if (
      navTreeNode['cabId'] === 'cab:auddefcat:auddefcat' ||
      navTreeNode['cabId'] === 'cab:audlistcat:audlistcat'
    ) {
      this.recordLimit = 20;
      if (this.isProductTypeDcdp) this.recordLimit = 10;
      searchId = this.searchId;
    }
    this.pickerService
      .fetchAttributes(
        this.contextId,
        this.dataUniverseId,
        this.activeDedupeType,
        this.recordLimit,
        navTreeNode['path'],
        navTreeNode['cabId'],
        startIndex,
        searchId,
        this.businessUnitId !== 'DEFAULT' ? this.businessUnitId : null
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response: any) => {
          if (!breadcrumbEvent && !this.loadMore) {
            this.breadcrumbs.push(navTreeNode);
          }
          const childNodes = response['results'].filter(
            (node) => node.id !== this.audienceDefinitionId
          );

          let orderedNodes = [];
          if (startIndex > 0) {
            const existingChildNodes = [...cloneDeep(navTreeNode.children), ...cloneDeep(childNodes)];
            navTreeNode.children = [];
            orderedNodes = existingChildNodes;
            if (
              !(navTreeNode['cabId'] === 'cab:auddefcat:auddefcat' ||
                navTreeNode['cabId'] === 'cab:audlistcat:audlistcat')
            ) {
              orderedNodes = orderBy(existingChildNodes, ['leaf', 'displayName']);
            }
            orderedNodes.forEach(
              (node) => {
                navTreeNode.children.push(node);
              }
            );
          } else {
            navTreeNode.children = [];
            orderedNodes = childNodes;
            if (
              !(navTreeNode['cabId'] === 'cab:auddefcat:auddefcat' ||
                navTreeNode['cabId'] === 'cab:audlistcat:audlistcat')
            ) {
              orderedNodes = orderBy(childNodes, ['leaf', 'displayName']);
            }
            orderedNodes.forEach((node) =>
              navTreeNode.children.push(node)
            );
          }
          this.loadMore = response?.hasMore ?? false;
          this.loadingNodes = false;
          this.currentNode = navTreeNode;
          this.rootNodes = cloneDeep(navTreeNode.children ?? []);
          this.searchId = response?.searchId ?? null;
        },
        error: (error) => {
          this.loadingNodes = false;
          this.generalErrorNotification.show();
          console.log(error);
        }
      });
  }

  navigateToLink(bIndex) {
    this.searchNodes = [];
    this.showSearchTree = false;
    this.searchId = null;
    if (bIndex !== null) {
      this.fetchChildrenNew(this.breadcrumbs[bIndex], true);
      this.breadcrumbs = this.breadcrumbs.slice(0, bIndex + 1);
    } else {
      this.breadcrumbs = [
        {
          displayName: 'All Criteria'
        }
      ];
      this.loadMore = false;
      this.currentNode = null;
      this.fetchRoot();
    }
    this.searchText = '';
    this.showSearchLimit = false;
  }

  attributeSearch(isLoadMore?: boolean) {
    let startIndex = 0;
    if (isLoadMore) {
      startIndex = this.searchNodes?.length ?? 0;
    } else {
      this.searchNodes = [];
    }
    if (this.searchText.trim().length < 3) {
      this.showSearchLimit = true;
      return;
    }
    this.showSearchLimit = false;
    if (!Object.keys(cabPathList).includes(this.currentNode['cabId'])) {
      this.selectedFilter =
        this.attributeSearchDropdownOptions.find(
          (attribute) => attribute.label === this.breadcrumbs[1]?.displayName
        )?.value ?? this.attributeSearchDropdownOptions[0]?.value;
      if (this.selectedFilter === 'DATA_SET_ATTRIBUTE') {
        if (!this.loadMore && this.rootNodes.length <= 1000) {
          this.searchNodes = this.rootNodes.filter((node) =>
            node.displayName
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          );
          this.showSearchTree = true;
          this.isRegularSearch = true;
          return;
        }
      } else {
        this.isRegularSearch = false;
      }
    }

    if (Object.keys(cabPathList).includes(this.currentNode['cabId'])) {
      this.selectedFilter = cabPathList[this.currentNode['cabId']];
    }

    let searchId = null;
    if (this.currentNode?.cabId === 'cab:datasetcat:datasetcat') {
      this.recordLimit = 1000;
    }
    else if (
      this.currentNode?.cabId === 'cab:auddefcat:auddefcat' ||
      this.currentNode?.cabId === 'cab:audlistcat:audlistcat'
    ) {
      searchId = isLoadMore ? this.searchId : null;
      this.recordLimit = 20;
    }
    this.isLoading = true;
    this.pickerService
      .searchAttributes(
        this.contextId,
        this.dataUniverseId,
        this.activeDedupeType,
        this.searchText,
        this.selectedFilter !== 'DATA_SET_ATTRIBUTE'
        ? this.selectedFilter
        : 'DATA_SET',
        this.currentNode?.cabId,
        this.rootNodes.length > 1000 && this.loadMore
          ? this.currentNode['path']
          : null,
        this.recordLimit,
        startIndex,
        searchId,
        this.businessUnitId !== 'DEFAULT' ? this.businessUnitId : null
      )
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          if (isLoadMore) {
            this.searchNodes = [...cloneDeep(this.searchNodes), ...cloneDeep(response.results)];
          } else {
            this.searchNodes = cloneDeep(response.results);
          }
          const childNodes = this.searchNodes.filter(
            (node) => node.id !== this.audienceDefinitionId
          );
          if (
            !(this.currentNode?.cabId === 'cab:auddefcat:auddefcat' ||
            this.currentNode?.cabId === 'cab:audlistcat:audlistcat')
          ) {
            this.searchNodes = orderBy(childNodes, ['leaf', 'displayName']);
          } else {
            this.searchNodes = cloneDeep(childNodes);
          }
          this.showSearchTree = true;
          this.isLoading = false;
          this.searchId = response?.searchId ?? null;
          this.loadMore = response?.hasMore ?? false;
        },
        error: (err) => {
          this.isLoading = false;
          console.log(err);
        }
      });
  }

  clearAttributeSearch() {
    this.showSearchTree = false;
    this.searchNodes = [];
    this.isRegularSearch = false;
    this.searchText = '';
    this.showSearchLimit = false;
    this.searchId = null;
    if (this.currentNode) {
      this.currentNode.children = [];
      this.fetchChildrenNew(this.currentNode, true);
    }
  }

  onBreadcrumbClicked(breadcrumbIndex) {
    this.navigateToLink(breadcrumbIndex === 0 ? null : breadcrumbIndex);
  }

  onLoadMore(isSearchEvent = false) {
    if (isSearchEvent) {
      this.onSearchUpdated(this.searchText, true);
    } else {
      this.fetchChildrenNew(this.currentNode, false);
    }
  }

  onSearchUpdated(searchText: string, isLoadMore?: boolean) {
    if (searchText.length) {
      this.searchText = searchText;
      this.attributeSearch(isLoadMore);
    } else {
      this.clearAttributeSearch();
    }
  }
}