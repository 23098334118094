import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { get } from 'lodash-es';
import { UtilsService } from '../utils/utilservice';

@Injectable({
  providedIn: 'root',
})
export class RequestLoadingService implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loadingService: LoadingService, private utilsService: UtilsService) {}

  removeRequest(req: HttpRequest<any>, isFullPageLoader = false) {
    const i = this.requests.indexOf(req);
    this.requests.splice(i, 1);
    if (isFullPageLoader) {
      this.loadingService.isHttpRequestLoadingFullScreen$.next(this.requests.length > 0);
    } else {
      this.loadingService.isHttpRequestLoading$.next(this.requests.length > 0);
    }
  }

  catchError(err: any) {
    if (err.status === 404) {
      this.loadingService.isEntityNotFound$.next(true);
    } else if(err.status === 500) {
      this.loadingService.isServerNotFound$.next(true);
      this.loadingService.serverErrorMessage$.next(err?.error?.errorDetails?.[0]?.errorMessage || 'There was an error while attempting your action. Please try again later.')
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const urls = this.utilsService.environmentValue('cabApiUrl');
    if(req.url?.startsWith(get(urls, 'cab'))) {
      let isFullPageLoader = false;
      this.loadingService.isEntityNotFound$.next(false);
      this.loadingService.isServerNotFound$.next(false);
      const params: HttpParams = req.params;
      // NOTE: when an http request has a param called 'abortLoad' defined, we want to avoid
      // showing a full-page load indicator
      if (params.get('fullPageLoader')) {
        this.requests.push(req);
        this.loadingService.isHttpRequestLoadingFullScreen$.next(true);
        isFullPageLoader = true;
      } else if (!params.get('abortLoad')) {
        this.requests.push(req);
        this.loadingService.isHttpRequestLoading$.next(true);
      }
      req = req.clone({
        params: req.params.delete('fullPageLoader')
      });
      return Observable.create((observer) => {
        const subscription = next.handle(req).subscribe(
          (event) => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req, isFullPageLoader);
              observer.next(event);
            }
          },
          (err) => {
            this.removeRequest(req, isFullPageLoader);
            this.catchError(err);
            observer.error(err);
          },
          () => {
            this.removeRequest(req, isFullPageLoader);
            observer.complete();
          }
        );
        return () => {
          this.removeRequest(req, isFullPageLoader);
          subscription.unsubscribe();
        };
      });
    } else {
      return next.handle(req);
    }
  }
}
