import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { logout } from '../utils/auth-utils';
import { AppState } from '../reducers';
import { selectContext } from '../context/context.reducer';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { UtilsService } from '../utils/utilservice';

//Commenting out to fix lint lissues
// function isLoginError(res: Response, passedUrl: string): boolean {
//   return res.url.includes(passedUrl) && get(res, 'error.error.refresh_login');
// }

function isTokenError(res: Response): boolean {
  //TODO: BE needs to give whether we need to redirect to login NOSONAR
  // return e.g. get(res, 'error.error.message', '').toUpperCase().includes('TOKEN');
  return res.status === 401;
}

function isAuthorizationError(res: Response): boolean {
  return res.status === 403;
}

@UntilDestroy()
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  contextId;
  constructor(private router: Router, private storage: LocalStorageService, public store: Store<AppState>,private utilsService: UtilsService) {
    this.store
      .select(selectContext)
      .pipe(untilDestroyed(this))
      .subscribe((context) => {
        this.contextId = context?.id;
      });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if(request.url?.startsWith(this.utilsService.getCabAPIUrl())) {
      const headers = {
        // TODO: need to remove okta token related code in separate PR NOSONAR
        // eslint-disable-next-line @typescript-eslint/naming-convention
        //Authorization: 'Bearer ' + oktaToken || '',
      };
      if(request.url?.startsWith(this.utilsService.getAMSProxyUrl())) {
        headers['x-cab-context'] = this.contextId;
      }
      request = request.clone({
        setHeaders: headers,
        withCredentials: true,
      });

      return next.handle(request).pipe(
        catchError((res) => {
          if (isTokenError(res)) {
            const params = {
              autoLogin: true
            };
            try {
              if(window.self !== window.top) {
                params['redirectURL'] = window.location.pathname;
              }
            } catch(err) {
              params['redirectURL'] = window.location.pathname;
            }
            logout(this.storage);
            this.router.navigate(['/login'], {
              queryParams: params,
            });
          } else if(isAuthorizationError(res)) {
            this.router.navigate(['/access-denied'], {});
          }
          return observableThrowError(res);
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
